import React from "react"
import { Link } from "gatsby-plugin-intl"
import { Layout, Breadcrumb } from "antd"
import Head from "../components/head"
import Footer from "../components/footer"
import lightLogo from "../images/2019-02-logo-monocromatico-blanco-digitalway.svg"

const { Header, Content } = Layout

export default () => {
  return (
    <div>
      <Head title="Digital Way | Política de privacidad" />
      <Header>
        <img src={lightLogo} alt="logo" width="70px" />
      </Header>
      <Content
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280,
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Política de privacidad</Breadcrumb.Item>
        </Breadcrumb>
        <h2>
          <b>POLITICAS PARA EL TRATAMIENTO DE DATOS PERSONALES</b>
        </h2>
        <p>
          La presente política se expide en cumplimiento a  la Ley 1581 de 2012
          y su Decreto reglamentario 1377 de 2013, con miras a proteger los
          derechos de los titulares. DW Agency S.A.S (NIT: 901.645.459 - 3), en
          adelante  “Digital Way” da a conocer su política para el tratamiento
          de datos personales, en adelante “La Política”
        </p>
        <h3>
          <b>Normatividad</b>
        </h3>
        <p>
          La Política fue elaborado en consideración a las disposiciones
          establecidas en los Artículos 15 y 20 del Capítulo I del Título II  de
          la Constitución Política de Colombia y en la Ley 1581 de 2012 “Por la
          cual se dictan disposiciones generales para la protección de datos
          personales”, en especial lo previsto en el literal K) del Artículo 17,
          así mismo el Decreto Reglamentario 1377 de 2013.
        </p>
        <h3>
          <b>Definiciones</b>
        </h3>
        <p>
          En el presente se tendrán como definiciones las siguientes, según lo
          establecido en el Artículo 3 de la Ley 1581 de 2012 y el Artículo 3
          del Decreto 1377 de 2013:
        </p>
        <ul>
          <li>
            <b>Titular:</b>
            <p>
              Persona natural cuyos datos personales sean objeto de Tratamiento.
            </p>
          </li>
          <li>
            <b>Dato privado:</b>
            <p>
              Es el dato que por su naturaleza íntima o reservada sólo es
              relevante para el titular.
            </p>
          </li>
          <li>
            <b>Dato semiprivado:</b>
            <p>
              Es semiprivado el dato que no tiene naturaleza íntima, reservada,
              ni pública y cuyo conocimiento o divulgación puede interesar no
              sólo a su titular sino a cierto sector o grupo de personas o a la
              sociedad en general.
            </p>
          </li>
          <li>
            <b>Dato público:</b>
            <p>
              Es el dato calificado como tal según los mandatos de la ley o de
              la Constitución Política y aquel que no sea semiprivado, privado o
              sensible. Son públicos, entre otros, los datos relativos al estado
              civil de las personas, a su profesión u oficio, a su calidad de
              comerciante o de servidor público y aquellos que puedan obtenerse
              sin reserva alguna. Por su naturaleza, los datos públicos pueden
              estar contenidos, entre otros, en registros públicos, documentos
              públicos, gacetas y boletines oficiales, sentencias judiciales
              debidamente ejecutoriadas que no estén sometidas a reserva.
            </p>
          </li>
          <li>
            <b>Datos sensibles:</b>
            <p>
              Se entiende por datos sensibles aquellos que afectan la intimidad
              del Titular o cuyo uso indebido puede generar su discriminación,
              tales como aquellos que revelen el origen racial o étnico, la
              orientación política, las convicciones religiosas o filosóficas,
              la pertenencia a sindicatos, organizaciones sociales, de derechos
              humanos o que promueva intereses de cualquier partido político o
              que garanticen los derechos y garantías de partidos políticos de
              oposición, así como los datos relativos a la salud, a la vida
              sexual y los datos biométricos.
            </p>
          </li>
          <li>
            <b>Base de Datos:</b>
            <p>
              Conjunto organizado de datos personales que sea objeto de
              Tratamiento. 
            </p>
          </li>
          <li>
            <b>Autorización:</b>
            <p>
              Consentimiento previo, expreso e informado del Titular para llevar
              a cabo el Tratamiento de datos personales.
            </p>
          </li>
          <li>
            <b>Transferencia: </b>
            <p>
              La transferencia de datos tiene lugar cuando el Responsable y/o
              Encargado del tratamiento de Datos Personales, ubicado en
              Colombia, envía la información o los Datos Personales a un
              Receptor, que a su vez es Responsable del Tratamiento y se
              encuentra fuera o dentro del país.
            </p>
          </li>
          <li>
            <b>Transmisión: </b>
            <p>
              Tratamiento de datos personales que implica la comunicación de los
              mismos dentro o fuera del territorio de la República de Colombia
              cuando tenga por objeto la realización de un Tratamiento por el
              Encargado por cuenta del Responsable.
            </p>
          </li>
          <li>
            <b>Tratamiento:</b>
            <p>
              Cualquier operación o conjunto de operaciones sobre datos
              personales, tales como la recolección, almacenamiento, uso,
              circulación o supresión.
            </p>
          </li>
          <li>
            <b>Responsable del Tratamiento:</b>
            <p>
              Persona natural o jurídica, pública o privada, que por sí misma o
              en asocio con otros, decida sobre la base de datos y /o el
              Tratamiento de los datos.
            </p>
          </li>
          <li>
            <b>Encargado del Tratamiento:</b>
            <p>
              Persona natural o jurídica, pública o privada, que por sí misma o
              en asocio con otros, realice el Tratamiento de datos personales
              por cuenta del Responsable del Tratamiento.
            </p>
          </li>
        </ul>
        <h3>
          <b>DERECHOS DEL TITULAR.</b>
        </h3>
        <ul>
          <li>
            <p>
              Ser informado por el Responsable del Tratamiento o el Encargado
              del Tratamiento, previa solicitud, respecto del uso que le ha dado
              a sus datos personales. 
            </p>
          </li>
          <li>
            <p>
              Conocer, actualizar y rectificar sus datos personales frente a los
              Responsables del Tratamiento o Encargados del Tratamiento. Este
              derecho se podrá ejercer, entre otros frente a datos parciales,
              inexactos, incompletos, fraccionados, que induzcan a error, o
              aquellos cuyo Tratamiento esté expresamente prohibido o no haya
              sido autorizado; 
            </p>
          </li>
          <li>
            <p>
              Acceder en forma gratuita a sus datos personales que hayan sido
              objeto de Tratamiento.
            </p>
            <p></p>
            <p>
              La lista anterior debe entenderse de manera enunciativa y no
              limitativa pues se entienden por Derechos del Titular todos
              aquellos conferidos por la Legislación Colombiana y las normas
              internacionales de aplicación en el País;
            </p>
          </li>
          <li>
            <p>
              Revocar la autorización y/o solicitar la supresión del dato cuando
              en el Tratamiento no se respeten los principios, derechos y
              garantías constitucionales y legales. La revocatoria y/o supresión
              procederá cuando la Superintendencia de Industria y Comercio haya
              determinado que en el Tratamiento el Responsable o Encargado han
              incurrido en conductas contrarias a la Ley y a la Constitución; 
            </p>
          </li>
          <li>
            <p>
              Solicitar prueba de la autorización otorgada al Responsable del
              Tratamiento salvo cuando expresamente se exceptúe como requisito
              para el Tratamiento, de conformidad con lo previsto en el artículo
              10 de la Ley 1581 de 2012; 
            </p>
          </li>
          <li>
            <p>
              Presentar ante la Superintendencia de Industria y Comercio quejas
              por infracciones a lo dispuesto en Ley 1581 de 2012 y las demás
              normas que la modifiquen, adicionen o complementen.
            </p>
          </li>
        </ul>
        <h3>
          <b>DEBERES Y OBLIGACIONES DE Digital Way</b>
        </h3>
        <h4>
          <b>Deberes como Responsable:</b>
        </h4>
        <p>
          De conformidad con lo establecido en el Artículo 17 de la Ley 1581 de
          2012 el Responsable está obligado a cumplir con los siguientes
          deberes: 
        </p>
        <ul>
          <li>
            <p>
              a) Garantizar al Titular, en todo tiempo, el pleno y efectivo
              ejercicio del derecho de hábeas data; 
            </p>
          </li>
          <li>
            <p>
              b) Solicitar y conservar, en las condiciones previstas en la Ley,
              copia de la respectiva Autorización otorgada por el Titular; 
            </p>
          </li>
          <li>
            <p>
              c) Informar debidamente al Titular sobre la finalidad de la
              recolección y los derechos que le asisten por virtud de la
              Autorización otorgada; 
            </p>
          </li>
          <li>
            <p>
              d) Conservar la información bajo las condiciones de seguridad
              necesarias para impedir su adulteración, pérdida, consulta, uso o
              acceso no autorizado o fraudulento; 
            </p>
          </li>
          <li>
            <p>
              e) Garantizar que la información que se suministre al Encargado
              del Tratamiento sea veraz, completa, exacta, actualizada,
              comprobable y comprensible; 
            </p>
          </li>
          <li>
            <p>
              f) Actualizar la información, comunicando de forma oportuna al
              Encargado del Tratamiento, todas las novedades respecto de los
              datos que previamente le haya suministrado y adoptar las demás
              medidas necesarias para que la información suministrada a este se
              mantenga actualizada; 
            </p>
          </li>
          <li>
            <p>
              g) Rectificar la información cuando sea incorrecta y comunicar lo
              pertinente al Encargado del Tratamiento; 
            </p>
          </li>
          <li>
            <p>
              h) Suministrar al Encargado del Tratamiento, según el caso,
              únicamente datos cuyo Tratamiento esté previamente autorizado de
              conformidad con lo previsto en la Ley;
            </p>
          </li>
          <li>
            <p>
              i) Exigir al Encargado del Tratamiento en todo momento, el respeto
              a las condiciones de seguridad y privacidad de la información del
              Titular; 
            </p>
          </li>
          <li>
            <p>
              j) Tramitar las consultas y reclamos formulados en los términos
              señalados en la Ley; 
            </p>
          </li>
          <li>
            <p>
              k) Adoptar un manual interno de políticas y procedimientos para
              garantizar el adecuado cumplimiento de lo previsto en la Ley 1581
              de 2012  y en especial, para la atención de consultas y reclamos; 
            </p>
          </li>
          <li>
            <p>
              l) Informar al Encargado del Tratamiento cuando determinada
              información se encuentra en discusión por parte del Titular, una
              vez se haya presentado la reclamación y no haya finalizado el
              trámite respectivo; 
            </p>
          </li>
          <li>
            <p>
              m) Informar a solicitud del Titular sobre el uso dado a sus
              datos; 
            </p>
          </li>
          <li>
            <p>
              n) Informar a la autoridad de protección de datos cuando se
              presenten violaciones a los códigos de seguridad y existan riesgos
              en la administración de la información de los Titulares. 
            </p>
          </li>
          <li>
            <p>
              o) Cumplir las instrucciones y requerimientos que imparta la
              Superintendencia de Industria y Comercio. 
            </p>
          </li>
        </ul>
        <h4>
          <b> Deberes como Encargado:</b>
        </h4>
        <p>
          De conformidad con lo establecido en el Artículo 18 de la Ley 1581 de
          2012 el Encargado está obligado a cumplir con los siguientes deberes:
        </p>
        <ul>
          <li>
            <p>
              a) Garantizar al Titular, en todo tiempo, el pleno y efectivo
              ejercicio del derecho de hábeas data; 
            </p>
          </li>
          <li>
            <p>
              b) Conservar la información bajo las condiciones de seguridad
              necesarias para impedir su adulteración, pérdida, consulta, uso o
              acceso no autorizado o fraudulento; 
            </p>
          </li>
          <li>
            <p>
              c) Realizar oportunamente la actualización, rectificación o
              supresión de los datos en los términos establecidos por la Ley; 
            </p>
          </li>
          <li>
            <p>
              d) Actualizar la información reportada por los Responsables del
              Tratamiento dentro de los cinco (5) días hábiles contados a partir
              de su recibo; 
            </p>
          </li>
          <li>
            <p>
              e) Tramitar las consultas y los reclamos formulados por los
              Titulares en los términos señalados en la Ley; 
            </p>
          </li>
          <li>
            <p>
              f) Adoptar un manual interno de políticas y procedimientos para
              garantizar el adecuado cumplimiento de la presente ley y, en
              especial, para la atención de consultas y reclamos por parte de
              los Titulares; 
            </p>
          </li>
          <li>
            <p>
              g) Registrar en la base de datos las leyenda "reclamo en trámite"
              en la forma en que se regula en la presente ley; 
            </p>
          </li>
          <li>
            <p>
              h) Insertar en la base de datos la leyenda "información en
              discusión judicial" una vez notificado por parte de la autoridad
              competente sobre procesos judiciales relacionados con la calidad
              del dato personal; 
            </p>
          </li>
          <li>
            <p>
              i) Abstenerse de circular información que esté siendo
              controvertida por el Titular y cuyo bloqueo haya sido ordenado por
              la Superintendencia de Industria y Comercio; 
            </p>
          </li>
          <li>
            <p>
              j) Permitir el acceso a la información únicamente a las personas
              que pueden tener acceso a ella; 
            </p>
          </li>
          <li>
            <p>
              k) Informar a la Superintendencia de Industria y Comercio cuando
              se presenten violaciones a los códigos de seguridad y existan
              riesgos en la administración de la información de los Titulares; 
            </p>
          </li>
          <li>
            <p>
              l) Cumplir las instrucciones y requerimientos que imparta la
              Superintendencia de Industria y Comercio. 
            </p>
          </li>
        </ul>
        <h3>
          <b>TRATAMIENTO DE LA INFORMACIÓN</b>
        </h3>
        <h4>
          <b> Canales de captura:</b>
        </h4>
        <p>
          Digital Way realizará la recolección de Datos Personales mediante los
          mecanismos que se enuncian a continuación:
        </p>
        <ul>
          <li>
            <b>Escrito:</b>
            <p>
              Es el medio a través del cual de manera física y presencial
              Digital Way, en el desarrollo de su objeto social, realizará la
              recolección de Datos Personales, a través del formato contenido en
              el Anexo 1 Aviso de Privacidad. 
            </p>
          </li>
          <li>
            <b>Telefónico:</b>
            <p>
              Es el medio a través del cual de manera audible, Digital Way
              realizará la recolección de Datos Personales de acuerdo con el
              formato contenido en el Anexo 2 Aviso de Privacidad.
            </p>
          </li>
          <li>
            <b>Virtual: </b>
            <p>
              Mecanismo a través del cual Digital Way, utilizando medios
              tecnológicos no presenciales habilitados previamente, que a la
              fecha son: la Pagina Web y las Cuentas Oficiales en Redes
              Sociales, realizará la recolección de Datos Personales, de acuerdo
              con el formato contenido en el Anexo 3 y bajo la aceptación de los
              términos y condiciones contenidos en el Anexo 4.
            </p>
          </li>
        </ul>
        <h4>
          <b>Campos de captura de la información:</b>
        </h4>
        <p>
          La recolección de datos personales por parte de Digital Way se
          limitará a aquellos datos personales que son pertinentes para la
          finalidad para la cual son recolectados o requeridos.
        </p>
        <h4>
          <b>Autorización de captura:</b>
        </h4>
        <p>
          Entiéndase por Autorización el consentimiento previo, expreso e
          informado del Titular para llevar a cabo el Tratamiento de Datos
          Personales. Toda Autorización debe contener la información que será
          recolectada sobre el Titular, así como todas las finalidades del
          Tratamiento para las cuales se obtiene. Esta autorizacion será válida
          mediante la aceptación de las políticas en formularios virtuales,
          firma en documento escrito o respuesta afirmativa a la solicitud de
          datos vía telefónica.
        </p>
        <h4>
          <b>Utilización de los Datos: </b>
        </h4>
        <p>
          El uso que Digital Way dará a los Datos Personales recolectados estará
          siempre sujeto a los principios de protección de Habeas Data
          consagrados en la Ley, y solo se entenderán autorizados los usos
          contemplados en La Política, y serán los siguientes:
        </p>

        <p>
          Digital Way podrá transferir y/o transmitir los datos personales
          sujetos a tratamiento a compañías que hagan parte de su grupo
          empresarial, esto es, a compañías matrices, filiales o subsidiarias.
          También podemos proporcionar su información a nuestros agentes y
          subcontratistas.
        </p>

        <p>
          Se podrá utilizar a terceros para entrega de promoción de productos,
          recaudo de sus pagos, enviar productos o tercerizar el sistema de
          servicio al cliente de Digital Way. Se podrá intercambiar información
          con terceros a efectos de protección contra el fraude y la reducción
          de riesgo de crédito,  transferir bases de datos de Digital Way que
          contienen su información personal en caso de ventas parciales o
          totales de  Digital Way
        </p>

        <p>
          También se podrán compartir los datos personales recolectados, con
          terceros que tengan la calidad de aliados comerciales y/o de negocio
          para que de manera conjunta puedan ofrecer productos y/o servicios que
          permitan mejorar la oferta de valor destinada a los clientes.
        </p>

        <p>
          El Sitio puede contener publicidad de terceros y enlaces a otros
          sitios o marcos de otros sitios. Digital Way no será responsable de
          las prácticas de privacidad o contenido de dichos terceros u otros
          sitios, ni por cualquier tercero a quien se transfieran sus datos de
          acuerdo con La Política.
        </p>

        <p>
          Teniendo en cuenta lo anterior los datos Personales serán utilizados
          para:
        </p>
        <ul>
          <li>
            <p>a) Proveer servicios y productos requeridos.</p>
          </li>
          <li>
            <p>
              b) Informar sobre nuevos productos o servicios que estén
              relacionados o no, con el contratado o adquirido por el Titular.
            </p>
          </li>
          <li>
            <p>c) Dar cumplimiento a obligaciones contraídas con el Titular.</p>
          </li>
          <li>
            <p>d) Informar sobre cambios en productos o servicios.</p>
          </li>
          <li>
            <p>e) Evaluar la calidad de productos o servicios. </p>
          </li>
          <li>
            <p>f) Desarrollar actividades de mercadeo o promocionales. </p>
          </li>
          <li>
            <p>
              g) Enviar al correo físico, electrónico, celular o dispositivo
              móvil, - vía mensajes de texto (SMS y/o MMS) información
              comercial, publicitaria o promocional sobre los productos y/o
              servicios, eventos y/o promociones de tipo comercial o no de
              estas, con el fin de impulsar, invitar, dirigir, ejecutar,
              informar y de manera general, llevar a cabo campañas, promociones
              o concursos de carácter comercial o publicitario.
            </p>
          </li>
          <li>
            <p>
              h) Compartir, incluyendo la transferencia y transmisión de datos
              personales a terceros para fines relacionados con la operación de
              Digital Way.
            </p>
          </li>
          <li>
            <p>
              i) Realizar estudios internos sobre el cumplimiento de las
              relaciones comerciales y estudios de mercado a todo nivel.
            </p>
          </li>
          <li>
            <p>
              j) Responder requerimientos legales de entidades administrativas y
              judiciales.
            </p>
          </li>
          <li>
            <p>
              k) Compartir los datos personales recolectados con terceros que
              tengan la calidad de aliados comerciales y/o de negocio para que
              de manera conjunta puedan ofrecer productos y/o servicios que
              permitan mejorar la oferta de valor destinada a los clientes, todo
              lo anterior de conformidad con lo dispuesto en la Ley 1581 de 2012
              y su Decreto Reglamentario 1377 de 2013. El tratamiento de datos
              personales, podrá implicar la recolección, almacenamiento, uso,
              circulación, supresión, transmisión, transferencia y/o recepción
              de datos, para la finalidad con que fueron suministrados. 
            </p>
          </li>
        </ul>
        <h3>
          <b>MEDIDAS DE SEGURIDAD</b>
        </h3>
        <p>
          Digital Way para el cuidado de los Datos Personales de terceros
          obtenidos a través de los Canales autorizados por La Política, ha
          dispuesto las medidas de seguridad que usa e implementa en relación a
          su información propia y que permiten concluir razonablemente que
          cuenta con adecuados modelos de gestión documental y protección de
          información para cumplir adecuadamente con sus obligaciones legales en
          relación con el cuidado y custodia de información de terceros.
        </p>
        <h4>
          <b>Procedimientos.</b>
          <p>  </p>
        </h4>
        <p>
          Digital Way cuenta con diferentes plataformas de software a través de
          las cuales, previo cumplimiento de las políticas de seguridad,
          administra, custodia, permite el acceso a personal autorizado y
          realiza el Tratamiento de los Datos.  El software destinado a dicho
          propósito es de propiedad de Digital Way y/o cuenta con las licencias,
          permisos y autorizaciones requeridos para su uso. 
        </p>
        <h3>
          <b>PROCEDIMIENTO DE ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS</b>
        </h3>
        <h4>
          <b>Consultas.</b>
        </h4>
        <p>
           Digital Way deberá atender las consultas  sobre la información
          personal del Titular que repose en cualquier base de datos que
          administre. Digital Way suministrará a estos la información contenida
          que esté vinculada con la identificación del Titular. 
        </p>

        <p>La consulta podrá formularse a través de los siguientes Canales:</p>
        <ul>
          <li>
            <p>El correo electrónico: hey@digitalway.guru</p>
          </li>
        </ul>
        <p>
          La consulta será atendida en un término máximo de diez (10) días
          hábiles contados a partir de la fecha de recibo de la misma en su
          domicilio principal o del registro de su solicitud en los Canales de
          atención previamente señalados. Cuando no fuere posible cumplir con
          este tiempo, se deberá informar al interesado expresando los motivos
          del retardo y la fecha en que se atenderá la consulta en un término no
          mayor a cinco (5) días hábiles siguientes al vencimiento del primer
          término.
        </p>
        <h4>
          <b>Reclamos.</b>
        </h4>
        <p>
          El Titular o sus causahabientes que consideren que la información
          contenida en las Bases de Datos debe ser objeto de corrección,
          actualización o supresión, o cuando adviertan el presunto
          incumplimiento de cualquiera de los deberes contenidos en la ley o en
          La Política, podrán presentar un reclamo ante Digital Way el cual será
          tramitado bajo las siguientes reglas: 
        </p>

        <p>
          El reclamo se formulará mediante solicitud dirigida al correo
          electrónico  
        </p>
        <p>hey@digitalway.guru</p>
        <p> y debe incluir lo siguiente:</p>
        <ul>
          <li>
            <p>Identificación del Titular, </p>
          </li>
          <li>
            <p>Descripción de los hechos que dan lugar al reclamo,  </p>
          </li>
          <li>
            <p>Dirección de notificaciones y respuestas y </p>
          </li>
          <li>
            <p>Los documentos o pruebas que se quiera hacer valer.</p>
          </li>
        </ul>
        <p>
          Si el reclamo resulta incompleto, se requerirá al Titular dentro de
          los cinco (5) días hábiles siguientes a la recepción del reclamo para
          que subsane las fallas. Transcurridos dos (2) meses desde la fecha del
          requerimiento, sin que el solicitante presente la información
          requerida, se entenderá que ha desistido del reclamo. 
        </p>

        <p>
          En el caso en que quien reciba el reclamo no sea competente para
          resolverlo, dará traslado a quien corresponda en un término máximo de
          dos (2) días hábiles e informará de la situación al interesado. 
        </p>

        <p>
          Una vez recibido el reclamo completo, el registro asociado al Titular
          pasará a un estado Inactivo, y se incluirá en la Base de Datos una
          leyenda que diga "reclamo en trámite" además tendrá asociado un
          registro de Solicitud del Cliente tipo Reclamo, que permita observar
          el motivo del mismo, en un término no mayor a dos (2) días hábiles.
          Dicha leyenda se mantendrá hasta que el reclamo sea decidido. 
        </p>

        <p>
          El término máximo para atender el reclamo será de quince (15) días
          hábiles contados a partir del día siguiente a la fecha de su recibo.
          Cuando no fuere posible atender el reclamo dentro de dicho término, se
          informará al interesado los motivos de la demora y la fecha en que se
          atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8)
          días hábiles siguientes al vencimiento del primer término. 
        </p>
        <h3>
          <b>VIGENCIA</b>
        </h3>
        <p>
          La Política será aplicable a las Bases de Datos personales de las
          cuales Digital Way es Responsable y Encargado; entrará en vigencia a
          partir del 2 de Marzo de 2017
        </p>

        <p>
          Las Bases de Datos y La Política tendrán un período de vigencia
          indeterminado de conformidad con la duración del objeto social de
          Digital Way. 
        </p>
        <h3>
          <b>MODIFICACIONES DE LA POLÍTICA</b>
        </h3>
        <p>
          Digital Way  podrá hacer modificaciones a La Política cuando así lo
          determine, dichas modificaciones deberán ser comunicadas a  los
          terceros a través digitalway.guru y hey@digitalway.guru
        </p>
      </Content>
      <Footer />
    </div>
  )
}
